import React from 'react';
import { Card, CardBody, CardText, CardTitle, Col } from 'reactstrap';
import { useTheme } from '../../layout/provider/Theme';
import WalletBalanceDetails from '../../utils/WalletBalanceDetails';
import { walletIcon, walletIconW } from '../../images/applogos';
import "./dashboard.css"
import { getCookie } from '../../utils/Utils';
import { Skeleton } from '@mui/material';

const WalletBalance = () => {
    const theme = useTheme();
    const { getWalletBalance } = WalletBalanceDetails();
    const userName = getCookie("username");

    // Format the number to show only the last 6 decimal places, if walletBalance is not null
    const lastSixDecimals = getWalletBalance?.data !== null ? getWalletBalance?.data?.stats?.balance.toFixed(6) : 'N/A';

    return (
        <>
            <Col xs={12} sm={6} md={12} className='pb-1 ms-1'>
                <p className="head-title">Welcome back,&nbsp;
                    <span>{userName ? userName : <Skeleton animation="wave" width={50} />}</span>
                </p>
            </Col>
            <Col xs={12} sm={6} md={4} className='pe-0'>
                <Card>
                    <CardBody className="py-2 rounded-5 side-shadow">
                        <img src={theme.skin === "dark" ? walletIconW : walletIcon}
                            alt="wallet-logo" className="my-1" style={{ height: "23px" }} />
                        <CardTitle style={{ fontSize: "12", color: "#FF2727" }} className=" mb-0">Wallet Balance</CardTitle>
                        <CardText
                            style={{
                                fontSize: "17px",
                                fontWeight: "500",
                                color: theme.skin === "light" ? "#2E1414" : "#fff",
                                display: "flex",
                            }}>
                            {lastSixDecimals ? lastSixDecimals : <Skeleton animation="wave" width={150} />}
                            &nbsp;&nbsp;SBC
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
}

export default WalletBalance;
