import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { TextField } from '@mui/material';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import WalletBalanceDetails from '../../utils/WalletBalanceDetails';
import { toast } from 'react-toastify';
import { getCookie } from './../../utils/Utils';
import { CusTextField } from '../../themes/CusComponent';
import { useTheme } from '../../layout/provider/Theme';

function PayPalComponent() {
    const { getWalletBalance } = WalletBalanceDetails();
    const [liveTokenValue, setLiveTokenValue] = useState(null);
    const [tokenValueNo, setTokenValueNo] = useState('');
    const [usd, setUsd] = useState(10);
    const theme = useTheme();

    useEffect(() => {
        if (getWalletBalance?.data) {
            const tokenValue = getWalletBalance.data.stats.live_token_value;
            setLiveTokenValue(tokenValue);
            const no_of_tokens = usd / tokenValue;
            setTokenValueNo(no_of_tokens.toFixed(6));
        }
    }, [getWalletBalance, usd]);

    const lastSixDecimals = liveTokenValue ? liveTokenValue.toFixed(6) : 'N/A';

    const handleToken = (e) => {
        const value = e.target.value;
        setTokenValueNo(value);
        const USD = value * liveTokenValue;
        setUsd(USD.toFixed(2));
    };

    const handleUsd = (e) => {
        const value = e.target.value;
        setUsd(value);
        const no_of_tokens = value / liveTokenValue;
        setTokenValueNo(no_of_tokens.toFixed(6));
    };

    const initialOptions = {
        "client-id": "AZDxjDScFpQtjWTOUtWKbyN_bDt4OgqaF4eYXlewfBP4-8aqX3PiV8e1GWU6liB2CUXlkA59kJXE7M6R",
        currency: "USD",
        intent: "capture",
    };

    return (
        <div className="container-fluid">
            <Row className="g-2">
                <Col xs={12} sm={6} md={12} className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-column'>
                        <CusTextField
                            label="Live Token Rate"
                            variant="standard"
                            value={lastSixDecimals}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                input: {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                    </div>
                    <div>
                        <CusTextField
                            label="TOKENS"
                            variant="standard"
                            value={tokenValueNo}
                            onChange={handleToken}
                            type='number'
                            sx={{
                                input: {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                    </div>
                    <div>
                        <CusTextField
                            label="USD"
                            variant="standard"
                            value={usd}
                            onChange={handleUsd}
                            type='number'
                            sx={{
                                input: {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} className="d-flex justify-content-center">
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                            className='w-25'
                            // style={{ layout: "vertical" }}
                            fundingSource="paypal"
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                currency_code: "USD",
                                                value: usd,  // Use the current state value of usd
                                            },
                                            payee: {
                                                email_address: getCookie("email", "joe@example.com"),
                                            },
                                        },
                                    ],
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then((details) => {
                                    toast.success(`Payment approved with Order ID: ${data.orderID}`);
                                });
                            }}
                            onError={() => {
                                toast.error("An error occurred with your payment");
                            }}
                        />
                    </PayPalScriptProvider>
                </Col>
            </Row>
        </div>
    );
}

export default PayPalComponent;
