import React from 'react'

const Support = () => {
  return (
    <>
    <div className='w-100 mt-5'>
        <h3 className='text-center'>Support !... </h3>
        <h3 className='text-center'>Coming Soon !... </h3>
    </div>
    </>
  )
}

export default Support;
