import { Autocomplete, Box, Button, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { postRequestData } from '../../utils/api-services';
import { toast } from 'react-toastify';
import { Android12Switch, CusAutocomplete, CusAutoNoBg, CusFiledTitle, CusTitle, Heading, SuccessBtn } from '../../themes/CusComponent';
import { useTheme } from '../../layout/provider/Theme';
import { Label } from 'reactstrap';

const Settings = () => {
  // const [productionId, setProductionId] = useState('');
  // const [sandboxId, setSandboxId] = useState('');
  // const [error, setError] = useState({ productionIdErr: false, sandboxIdErr: false });
  const theme = useTheme();

  // const [modalSm, setModalSm] = useState(false);
  // const toggleSm = () => setModalSm(!modalSm);

  // const validateFields = () => {
  //   let isValid = true;
  //   const newError = { productionIdErr: false, sandboxIdErr: false };

  //   if (!productionId) {
  //     newError.productionIdErr = true;
  //     isValid = false;
  //   }
  //   if (!sandboxId) {
  //     newError.sandboxIdErr = true;
  //     isValid = false;
  //   }

  //   setError(newError);
  //   return isValid;
  // };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   if (validateFields()) {
  //     try {
  //       const payload = {
  //         "paypal_production_client_id": productionId,
  //         "paypal_sandbox_client_id": sandboxId,
  //       }
  //       const response = await postRequestData(payload, `api/v1/wallet/paypal-settings`);
  //       if (response.status) {
  //         toast.success(`Paypal Client Id Update Request Successfully...`);
  //       } else {
  //         toast.error(response?.message);
  //       }
  //     } catch (error) {
  //       console.log(`An error occurred in Settings page on PayPal Client Id Update: ${error}`);
  //     }
  //   }
  // };

  const Logs = [
    { title: "Euro", value: "EUR" },
    { title: "United States", value: "USD" },
    // { title: "Request Log" },
  ];
  return (
    <>
      {/* <Modal size="md" isOpen={modalSm} toggle={toggleSm}>
        <ModalHeader toggle={toggleSm}>
          <p className="fs-6">PayPal Client ID</p>
        </ModalHeader>
        <ModalFooter className="bg-light">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box component="form">
                <TextField
                  fullWidth
                  label="Paypal Production Client Id"
                  color="secondary"
                  size="small"
                  value={productionId}
                  onChange={(e) => setProductionId(e.target.value)}
                  className='mt-2'
                  error={error.productionIdErr}
                  helperText={error.productionIdErr ? "Please enter a valid Production Client ID." : ""}
                />
                <TextField
                  fullWidth
                  label="Paypal Sandbox Client Id"
                  color="secondary"
                  size="small"
                  value={sandboxId}
                  onChange={(e) => setSandboxId(e.target.value)}
                  className='mt-2'
                  error={error.sandboxIdErr}
                  helperText={error.sandboxIdErr ? "Please enter a valid Sandbox Client ID." : ""}
                />
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" className="mt-3">
                  <Button color="secondary" variant="outlined" onClick={handleUpdate}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ModalFooter>
      </Modal> */}
      {/* <div className='w-100 mt-5'>
        <h5 className='ms-5' onClick={() => setModalSm(true)}>Paypal Client ID</h5>
      </div> */}

      <div className="container-fluid overflow-auto" style={{ height: `calc(100vh - 121px)` }}>
        <Grid container columnSpacing={2} spacing={0}>
          <Grid item xs={12} sm={6} md={12} sx={{
            borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
          }}
          >
            <Heading className={`mt-3 ms-5 p-2 text-${theme.skin === "dark" ? "white" : "black"}`}>Account</Heading>
          </Grid>
        </Grid>

        {/* Personal Info Section */}
        <Grid container columnSpacing={2} className=' py-sm-1 py-md-2 py-lg-4' spacing={0} sx={{
          borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
        }}>
          <Grid item xs={12} sm={6} md={12} className='py-1'>
            <CusTitle className='ms-4'>Personal Info</CusTitle>
          </Grid>
          <Grid container direction="row" className='ms-5 m-2'>
            <Grid item xs={12} sm={6} md={2}>
              <CusFiledTitle>First Name</CusFiledTitle>
              <Typography>...........</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CusFiledTitle>Last Name</CusFiledTitle>
              <Typography>...........</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CusFiledTitle>E-mail</CusFiledTitle>
              <Typography>...........</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}
              className='d-flex align-items-end p-2'
              sx={{ gap: 1 }}
            >
              <SuccessBtn size='small' sx={{
                background: theme.skin === "dark" ? '#16393a' : "#59f1f6a9",
                color: theme.skin === "dark" ? '#1ee0ac' : "#000",
              }}>
                Verified
              </SuccessBtn>
              <Button size='small' sx={{ color: theme.skin === "dark" ? "#fff" : "#000", background: "#a5a6a91c" }}>
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} spacing={0} className='py-sm-1 py-md-2 py-lg-4' sx={{
          borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
          paddingLeft: "25px"
        }}>
          <Grid item xs={12} sm={6} md={12} className='py-1'>
            <CusTitle>Local Currency</CusTitle>
            <Typography sx={{ fontSize: "12px", marginTop: "5px" }}>
              All charts and currencies will be converted to this denomination</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className='my-2'>
            <Typography variant='body2'>Select a Currency</Typography>
            <CusAutoNoBg
              options={Logs}
              className="mt-1"
              fullWidth
              size="small"
              getOptionLabel={(option) => option.title}
              // value={value}
              // onChange={(event, newValue) => setValue(newValue)}
              renderOption={(props, option) => (
                <li {...props} className="d-flex justify-content-between px-2">
                  <div>{option.title}</div>
                  <div>{option.value}</div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Search and Select"
                  variant="outlined"
                />
              )}
              sx={{
                background: theme.skin === "dark" ? "#a5a6a91c" : "#a5a6a970",
                '& .MuiInputBase-root': {
                  color: theme.skin === 'dark' ? '#fff' : '#000',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  color: theme.skin === "light" ? "#000" : "#fff",
                },
                '& .MuiAutocomplete-clearIndicator': {
                  color: theme.skin === "light" ? "#000" : "#fff",
                },
                '& .MuiAutocomplete-paper': {
                  background: theme.skin === "dark" ? "#a5a6a91c" : "",
                },
              }}
            />
            {/* <FormControlLabel
              control={<Android12Switch defaultChecked />}
              label="Android 12"
            /> */}
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} spacing={0} className='py-sm-1 py-md-2 py-lg-4' sx={{
          borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
          paddingLeft: "25px"
        }}>
          <Grid item xs={12} sm={6} md={12} className='py-1'>
            <CusTitle>Show Notifications</CusTitle>
            <Typography sx={{ fontSize: "12px", marginTop: "5px" }}>
              Display order activity stacked notifications.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className='my-2'>
            <Typography variant='body2'>Choose whether to show notifications</Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Settings;
