import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppHeader from "./header/AppHeader";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";

import { useTheme } from "./provider/Theme";
import FileManagerProvider from "../pages/app/file-manager/components/Context";
import Footer from "./footer/Footer";

const Layout = ({ title, app, ...props }) => {
  const theme = useTheme();

  return (
    <FileManagerProvider>
      <Head title={!title && 'Loading'} />
      <AppRoot className="npc-apps apps-only">
        {/* <Appbar /> */}
        <AppMain>

          <AppWrap>
            <AppHeader app={app} fixed />
            <div className="d-flex flex-row">
              <Sidebar fixed />
              <Outlet />
            </div>
            <Footer />
          </AppWrap>
        </AppMain>
      </AppRoot>
    </FileManagerProvider>
  );
};
export default Layout;
