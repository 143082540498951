import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showApp: undefined,
};

const siteSettingSlice = createSlice({
  initialState,
  name: "site-setting",
  reducers: {
    setSiteSettingHandler: (state, { payload }) => {
      state.showApp = payload.showApp === "1" ? true : false;
    },
  },
});

export const siteSettingAction = siteSettingSlice.actions;

const siteSettingReducer = siteSettingSlice.reducer;
export default siteSettingReducer;
