import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { postRequestData } from '../../utils/api-services';
import dp from "../../images/applogos/profile.png";
import WalletBalanceDetails from '../../utils/WalletBalanceDetails';
import { toast } from 'react-toastify';
import "../DashBoard/dashboard.css";
import { CusAutocomplete, CusTextField } from '../../themes/CusComponent';
import { useTheme } from '../../layout/provider/Theme';

const TransferTokens = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [tokens, setTokens] = useState('');
    const [userData, setUserData] = useState([]);
    const [aguerFee, setAguerFee] = useState(0);
    const [totalPayableToken, setTotalPayableToken] = useState(0);
    const [usd, setUsd] = useState();
    const theme = useTheme();
    const { getWalletBalance } = WalletBalanceDetails();

    const [userError, setUserError] = useState(false);
    const [tokensError, setTokensError] = useState(false);

    const handleUserChange = (event, value) => {
        setSelectedUser(value);
        if (value) {
            setUserError(false);
        }
    };

    const handleTokens = (event) => {
        const value = event.target.value;
        setTokens(value);
        if (value) {
            setTokensError(false);
        }
    };

    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await postRequestData(selectedUser, `api/v1/wallet/users`);
                if (response?.data?.users) {
                    setUserData(response?.data?.users);
                }
            } catch (error) {
                console.log(`An error occurred in Transfer Tokens page: ${error}`);
            }
        };

        getUserData();
    }, [selectedUser]); // Added selectedUser as a dependency

    useEffect(() => {
        if (tokens && getWalletBalance?.data?.stats?.auger_fee_percentage) {
            const tokenValue = parseFloat(tokens);
            const feePercentage = parseFloat(getWalletBalance.data.stats.auger_fee_percentage);

            const tempAgure = (tokenValue * feePercentage) / 100;
            setAguerFee(tempAgure.toFixed(6));

            const temptotalPayableToken = tokenValue + tempAgure;
            setTotalPayableToken(temptotalPayableToken.toFixed(6));

            const tempUsd = temptotalPayableToken * getWalletBalance?.data?.stats?.tokens_usd_value;
            setUsd(tempUsd.toFixed(6));
        }
    }, [tokens, getWalletBalance?.data?.stats?.auger_fee_percentage, getWalletBalance?.data?.stats?.tokens_usd_value]);

    const validateFields = () => {
        let isValid = true;

        if (!selectedUser) {
            setUserError(true);
            isValid = false;
        }

        if (!tokens) {
            setTokensError(true);
            isValid = false;
        }

        return isValid;
    };

    const transferTokens = async () => {
        if (validateFields()) {
            try {
                const payload = {
                    "receiver_id": selectedUser.id,
                    "txn_tokens": parseInt(tokens),
                };
                const response = await postRequestData(payload, `api/v1/wallet/transfer`);
                if (response.status) {
                    toast.success(`Tokens Are Transfer Successfully...`);
                } else {
                    toast.error(response?.message);
                }
            } catch (error) {
                console.log(`An error occurred in Transfer Tokens page: ${error}`);
            }
        }
    };
    const requestTokens = async () => {
        if (validateFields()) {
            if (parseInt(tokens) > 100) {
                try {
                    const payload = {
                        "type": 1,
                        "to_user_id": selectedUser.id,
                        "txn_tokens": parseInt(tokens),
                    };
                    const response = await postRequestData(payload, `api/v1/wallet/request`);
                    if (response.status) {
                        toast.success(`Tokens Request Successfully...`);
                    } else {
                        toast.error(response?.message);
                    }
                } catch (error) {
                    console.log(`An error occurred in Transfer Tokens page: ${error}`);
                }
            } else {
                toast.error("Tokens should be greater than 100");
            }
        }
    };

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={6}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <CusAutocomplete
                            fullWidth
                            size="small"
                            options={userData}
                            getOptionLabel={(option) => option.username}
                            value={selectedUser}
                            onChange={handleUserChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Users"
                                    error={userError}
                                    helperText={userError ? "Please select a user." : ""}
                                />
                            )}
                            sx={{
                                '& .MuiInputBase-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                    color: theme.skin === "light" ? "#000" : "#fff",
                                },
                                '& .MuiFormLabel-root': {
                                    color: theme.skin === "light" ? "#000" : "#fff",
                                  },
                            }}
                        />
                        <img
                            src={selectedUser?.profile_image_path ? selectedUser.profile_image_path : dp}
                            alt={`${selectedUser?.username}'s profile`}
                            className='rounded-circle my-3 border border-danger border-2'
                            style={{ height: "150px" }}
                        />
                        <Typography variant="h6" className='pt-1'>
                            {selectedUser ? selectedUser.username : "No User Selected!..."}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Grid container direction="column" gap={0.6}>
                        <Typography className='fs-6 fw-bold'>Token :&nbsp;
                            {getWalletBalance?.data?.stats?.balance.toFixed(6)}
                        </Typography>
                        <Typography className='fs-6 fw-bold'>Live Token Value :&nbsp;
                            {getWalletBalance?.data?.stats?.live_token_value.toFixed(6)}
                        </Typography>
                        <Typography className='fs-6 fw-bold'>Auger Fee :&nbsp;
                            {aguerFee}
                        </Typography>
                        <Typography className='fs-6 fw-bold'>Total Payable Token :&nbsp;
                            {totalPayableToken}
                        </Typography>
                        <Typography className='fs-6 fw-bold'>Total USD Value :&nbsp;
                            {usd}
                        </Typography>
                        <CusTextField
                            type="number"
                            label="Enter Number of Tokens"
                            id="outlined-size-small"
                            size="small"
                            value={tokens}
                            onChange={handleTokens}
                            className='mt-2'
                            error={tokensError}
                            helperText={tokensError ? "Please enter a valid number of tokens." : ""}
                            sx={{
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                        <div className='py-1 d-flex flex-row justify-content-between'>
                            <Button className='wallet-button' variant="outlined" onClick={transferTokens}>
                                Transfer Tokens
                            </Button>
                            <Button className='wallet-button' variant="outlined" onClick={() => requestTokens()}>
                                Request Tokens
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default TransferTokens;
