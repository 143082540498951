import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import SimpleBar from "simplebar-react";
import styles from "../../../../assets/scss/_themes-vars.module.scss";
import styleVar from "../../../../assets/scss/_themes-vars.module.scss";
import "./notification.scss";
import { getAllNotificationHandler, setNotificationCountHandler } from "../../../../utils/api-services";
import Icon from "../../../../components/icon/Icon";
import { useThemeUpdate } from "../../../provider/Theme";
import { shortenString } from "../../../../utils/Utils";
import { TooltipComponent } from "../../../../components/Component";
import NotificationSkeletonLoader from "./NotificationSkeletonLoader";
import EventNotification from "./EventNotification";
// import { getCategoryValueByLabel } from "../../../../components/partials/calender/CalenderData";
import { TriggerBtnContext } from "../../../../App";

function NotificationItem({ title, time, isSender, link, updatedAt, recent, index, notification }) {
  const [isOpen, setIsOpen] = useState(false);
  const EventToggle = () => setIsOpen(!isOpen);
  const [eventChildData, setEventChildData] = useState();
  const [eventTheme, setEventTheme] = useState();

  const handleModuleNotifications = (notification) => {
    switch (notification.module) {
      case "6":
        setIsOpen(true);
        break;
      default:
        console.log(`Component Not Available!...`);
        break;
    }
  };

  // const handleChildData = (data) => {
  //   setEventChildData(data);
  //   if (data) {
  //     const te = getCategoryValueByLabel(data.category);
  //     setEventTheme(te);
  //   }
  // };

  return (
    <>
      {/* <Modal isOpen={isOpen} toggle={EventToggle}>
        <ModalHeader className={eventTheme && eventTheme} toggle={() => EventToggle()}>
          {eventChildData && eventChildData.event_title}
        </ModalHeader>
        <ModalBody>
          <EventNotification onSendChildData={handleChildData} eventId={notification.reference_id} />
        </ModalBody>
      </Modal> */}
      <li
        className="p-4 web-app"
        onClick={() => {
          handleModuleNotifications(notification);
        }}
      >
        <div className="d-flex justify-content-between align-items-start w-100">
          <div className="d-flex align-items-center gap-2">
            <div className="nk-notification-icon">
              <Icon
                name={isSender ? "curve-down-right" : "curve-down-left"}
                className={[`icon-circle ${isSender ? "bg-primary-dim" : "bg-success-dim"}`]}
              />
            </div>
            <div className="nk-notification-content">
              <div
                className="nk-notification-text"
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {title.length > 27 ? (
                  <TooltipComponent
                    type="text"
                    content={shortenString(title, 27)}
                    direction="top"
                    id={`notification-${index}`}
                    text={title}
                  />
                ) : (
                  title
                )}
              </div>
              <div className="nk-notification-time">{time}</div>
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "600" }}>{moment(updatedAt).format("h:MM A")}</div>
          </div>
        </div>
      </li>
    </>
  );
}

const Notification = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState({ notifications: [] });
  const [limit, setLimit] = useState(10);
  const [notificationIds, setNotificationIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { triggerFetch, toggleFetch } = useContext(TriggerBtnContext);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getAllNotificationHandler({ limit: limit });
      if (response.status) {
        const unReadNotifications = Array.isArray(response.data.notifications)
          ? response.data.notifications.filter((notification) => notification.seen === "0")
          : [];
        setNotificationCount(unReadNotifications.length || 0);

        const newNotifications = response.data.notifications.filter((notification) => {
          return !notificationData.notifications.some(
            (existingNotification) => existingNotification.id === notification.id
          );
        });

        setNotificationData((prevNotification) => ({
          notifications: [...prevNotification.notifications, ...newNotifications],
        }));

        const notificationsId = response.data.notifications.map((notification) => notification.id);
        setNotificationIds(notificationsId);
      }
      //  else if (response.redirect) {
      //   window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
      // }
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [triggerFetch, limit]);

  const loadMoreNotifications = async () => {
    setLimit((prevLimit) => prevLimit + 10);
  };

  const markAllRead = async () => {
    try {
      const data = await setNotificationCountHandler({ notification_id: 0, status: 1, module: 6 });
      if (!data.status) {
        toast.error(data?.message);
        // if (data?.redirect) {
        //   window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        // }
      } else {
        toast.success(data?.message);
        setNotificationCount(0);
        setNotificationData((prevData) => ({
          notifications: prevData.notifications.map((notification) => ({
            ...notification,
            seen: "1",
          })),
        }));
        toggleFetch(loading);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dropdown
      isOpen={open}
      toggle={toggle}
      className="user-dropdown"
    >
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="icon-status icon-status-na">
          <Icon name="bell-fill" />
          {
            notificationCount > 0 &&
            <div color="primary" pill className="countIcon">
              {loading ? 0 : notificationCount}
            </div>
          }
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head d-flex justify-content-between align-items-center">
          <div>Notifications</div>
          <div
            size="small"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (notificationCount <= 0) {
                toast.error("There are no unread messages.");
              } else {
                markAllRead();
              }
            }}
            startIcon={
              loading ? <Spinner size={"sm"} color="primary" style={{ borderWidth: "1px" }} /> : ""
            }
          >
            Mark All as Read
          </div>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification dropdownMenu">
            {loading ? (
              <NotificationSkeletonLoader />
            ) : error ? (
              <div
                style={{
                  padding: "1.25rem",
                  textAlign: "center",
                  color: styleVar.errorMain,
                  fontWeight: "600",
                  textTransform: "capitalize",
                }}
              >
                {error.message}
              </div>
            ) : notificationData.notifications.length > 0 ? (
              <SimpleBar>
                <ul>
                  {notificationData.notifications.map((notification, index) => (
                    <NotificationItem
                      key={index}
                      index={index}
                      title={notification.title}
                      time={moment(notification.created_at).format("DD MMM")}
                      isSender={notification.is_sender}
                      link={notification.link}
                      updatedAt={notification.updated_at}
                      recent={notification.recent}
                      notification={notification}
                    />
                  ))}
                </ul>
                <div className="dropdown-foot center">
                  <div
                    style={{ cursor: "pointer" }}
                    // endIcon={
                    //   loading ? (
                    //     <Spinner size="sm" style={{ borderWidth: "1px", color: "inherit" }} />
                    //   ) : (
                    //     ""
                    //   )
                    // }
                    onClick={loadMoreNotifications}
                    disabled={loading}
                  >
                    {"View More"}
                  </div>
                </div>
              </SimpleBar>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div
                  style={{ padding: "1.25rem", color: styles.primaryMain }}
                >
                  No Notification Found
                </div>
              </div>
            )}
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
