import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import LayoutApp from "../layout/Index-app";
import Dashboard from "../pages/DashBoard/DashBoard";
import Transactions from "../pages/DashBoard/Transactions";
// import { getCookie } from "../utils/Utils";
import Settings from "../pages/DashBoard/Settings";
import Support from "../pages/DashBoard/Support";
import { getCookie } from "../utils/Utils";
import Cookies from 'js-cookie';


const Pages = () => {
  const location = useLocation();
  const authToken = getCookie("authToken");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (!authToken) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // Fixed the multiplication operator
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });

      window.location.href = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      try {
        // KYC status check logic can be added here if necessary
      } catch (error) {
        console.error("Error checking KYC status:", error);
      }
    }
  }, [authToken]);


  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<LayoutApp app={{ icon: "user", theme: "bg-success-dim", text: "Account" }} />}
        errorElement={<Error404Modern />}
      >
        <Route path="/" element={<Dashboard />} />
        <Route path='/transactions' element={<Transactions />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/support" element={<Support />} />
      </Route>

      {/* <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}> */}
      <Route path="errors">
        <Route path="404-modern" element={<Error404Modern />}></Route>
        <Route path="404-classic" element={<Error404Classic />}></Route>
        <Route path="504-modern" element={<Error504Modern />}></Route>
        <Route path="504-classic" element={<Error504Classic />}></Route>
      </Route>
      <Route path="*" element={<Error404Modern />}></Route>

      {/* </Route> */}
    </Routes>
  );
};
export default Pages;
