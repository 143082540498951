import React from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import Menu from "../menu/Menu";

import { useTheme, useThemeUpdate } from '../provider/Theme';
import NewMenu from "../menu/NewMenu";
import { useMediaQuery } from "@mui/material";

const Sidebar = ({ fixed, className }) => {


  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  

  // const classes = classNames({
  //   "nk-sidebar": window.innerWidth < 1200 ? true : false,
  //   // "nk-sidebar-fixed": fixed,
  //   "nk-sidebar-active": theme.sidebarVisibility,
  //   "nk-sidebar-mobile": theme.sidebarMobile,
  //   [`is-light`]: theme.sidebar === "white",
  //   [`is-${theme.sidebar}`]: theme.sidebar !== "white" && theme.sidebar !== "light",
  //   [`${className}`]: className,
  // });
  return (
    <>
      <div className={` new-sidebar`}
      // style={{ background: "#FEFCFF", width: "168px", height: "full" }}
      >
        <SimpleBar className="nk-sidebar-inner p-0 mt-3">
          {/* <Menu /> */}
          <NewMenu />
        </SimpleBar>
      </div>
      {theme.sidebarVisibility && <div
        onClick={themeUpdate.sidebarVisibility}
        className="nk-sidebar-overlay"></div>}
    </>
  );
};
export default Sidebar;
