import { configureStore } from "@reduxjs/toolkit";
import siteSettingReducer from "./slices/site-setting-slice";
import muiReducer from "./slices/mui-slice";

//slices

const store = configureStore({
    reducer: {
        siteSetting: siteSettingReducer,
        mui: muiReducer
    }
})

export default store;