import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import WalletBalanceDetails from '../../utils/WalletBalanceDetails';
import { toast } from 'react-toastify';
import { postRequestData } from '../../utils/api-services';
import "../DashBoard/dashboard.css";
import { CusTextField } from '../../themes/CusComponent';
import { useTheme } from '../../layout/provider/Theme';

const WithdrawalToken = () => {

    const [tokens, setTokens] = useState('');
    const [aguerFee, setAguerFee] = useState(0);
    const [totalPayableToken, setTotalPayableToken] = useState(0);
    const [usd, setUsd] = useState();
    const { getWalletBalance } = WalletBalanceDetails();
    const [tokensError, setTokensError] = useState(false);
    const theme = useTheme();

    const handleTokens = (event) => {
        const value = event.target.value;
        setTokens(value);
        if (value) {
            setTokensError(false);
        }
    };

    useEffect(() => {
        if (tokens && getWalletBalance?.data?.stats?.auger_fee_percentage) {
            const tokenValue = parseFloat(tokens);
            const feePercentage = parseFloat(getWalletBalance.data.stats.auger_fee_percentage);

            const tempAgure = (tokenValue * feePercentage) / 100;
            setAguerFee(tempAgure.toFixed(6));

            const temptotalPayableToken = tokenValue + tempAgure;
            setTotalPayableToken(temptotalPayableToken.toFixed(6));

            const tempUsd = temptotalPayableToken * getWalletBalance?.data?.stats?.live_token_value;
            setUsd(tempUsd.toFixed(6));
        }
    }, [tokens,
        getWalletBalance?.data?.stats?.auger_fee_percentage,
        getWalletBalance?.data?.stats?.tokens_usd_value,
        getWalletBalance?.data?.stats?.live_token_value
    ]);

    const validateFields = () => {
        let isValid = true;

        if (!tokens) {
            setTokensError(true);
            isValid = false;
        }

        return isValid;
    };

    const withdarwalRequest = async () => {
        if (validateFields()) {
            try {
                const payload = {
                    "type": 2,
                    "txn_tokens": parseInt(tokens),
                };
                const response = await postRequestData(payload, `api/v1/wallet/request`);
                if (response.status) {
                    toast.success(`Withdrawal Request Successfully...`);
                } else {
                    toast.error(response?.message);
                }
            } catch (error) {
                console.log(`An error occurred in Withdrawal page: ${error}`);
            }
        }
    };

    return (
        <>
            <Grid container spacing={1} >
                <Grid item xs={12} sm={6} md={6}>
                    <Grid container direction="column" paddingLeft={8} gap={1}>
                        <Typography className='fs-5 fw-bold'>Token</Typography>
                        <Typography className='fs-5 fw-bold'>Live Token Value</Typography>
                        <Typography className='fs-5 fw-bold'>Total USD Value</Typography>
                        <Typography className='fs-5 fw-bold'>Auger Fee</Typography>
                        <Typography className='fs-5 fw-bold'>Total Payable Token</Typography>
                        <Typography className='fs-5 fw-bold'>Enter Tokens</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Grid container direction="column" paddingLeft={5} gap={1}>
                        <Typography className='fs-6 fw-bold'>
                            {getWalletBalance?.data?.stats?.balance.toFixed(6)
                                ? getWalletBalance?.data?.stats?.balance.toFixed(6)
                                : 0}
                        </Typography>
                        <Typography className='fs-6 fw-bold'>
                            {getWalletBalance?.data?.stats?.live_token_value.toFixed(6)
                                ? getWalletBalance?.data?.stats?.live_token_value.toFixed(6)
                                : 0}
                        </Typography>
                        <Typography className='fs-6 fw-bold'>
                            {usd ? usd : 0}
                        </Typography>
                        <Typography className='fs-6 fw-bold'> {aguerFee ? aguerFee : 0}</Typography>
                        <Typography className='fs-6 fw-bold'>{totalPayableToken}</Typography>
                        <CusTextField
                            type="number"
                            label="Enter Number of Tokens"
                            id="outlined-size-small"
                            size="small"
                            value={tokens}
                            onChange={handleTokens}
                            className='mt-2'
                            error={tokensError}
                            helperText={tokensError ? "Please enter a valid number of tokens." : ""}
                            sx={{
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Button className='wallet-button' variant="outlined" onClick={() => withdarwalRequest()}>
                            Request Tokens
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default WithdrawalToken;
