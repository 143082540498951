

import React, { useEffect, useState } from "react";
import { getCookie } from "../../../../utils/Utils";
import UserAvatar from "../../../../components/user/UserAvatar"; // Assume you have a UserAvatar component in the same folder
import dp from "../../../../images/applogos/profile.png";

const AccountAvatar = ({ profileName }) => {
  const [isAvatarImage, setIsAvatarImage] = useState(false);
  const [imageUrl, setFileURL] = useState(null);

  useEffect(() => {
    getProfileImage();
  }, []);

  const getProfileImage = async () => {
    try {
      let res = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/account/get-only-profile-img`,
        {
          method: "POST",
          cache: "no-store",
          headers: {
            authToken: getCookie("authToken", null),
          },
        }
      );
      if (res.ok) {
        const blob = await res.blob();
        const imageUrl = URL.createObjectURL(blob);
        setIsAvatarImage(true);
        setFileURL(imageUrl);
      }
    } catch (error) {
      console.error("Error fetching profile image", error);
    }
  };

  if (isAvatarImage) {
    return (
      <UserAvatar
        image={imageUrl}
        imageAlt={`profile-img2`}
        className="rounded-circle "
      />
    );
  }

  return (
    <div className=" ">
      <img
        src={dp}
        alt={profileName}
        style={{ height: "40px" }}
        className="profileLoginUser me-2"

      />
    </div>
  );
};

export default AccountAvatar;

