import { createTheme } from "@mui/material/styles";

// assets
import colors from "../assets/scss/_themes-vars.module.scss";
import styleVar from "../assets/scss/_themes-vars.module.scss";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export const theme = (customization) => {
    const themeOption = {
        colors,
        heading: colors.grey900,
        paper: colors.paper,
        backgroundDefault: colors.paper,
        background: colors.primaryLight,
        customization,
    };
    // console.log(themeOption.customization.mode);

    const themeOptions = {
        direction: "ltr",
        palette: themePalette(themeOption),
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        borderColor: customization.mode === "dark" ? styleVar.borderDark : styleVar.borderWhite,
                        '& .MuiOutlinedInput-root': {
                            borderRadius: "24px",
                            height: "100%",
                            '& fieldset': {
                                borderColor: customization.mode === "dark" ? styleVar.borderDark : styleVar.borderWhite,
                            },
                            '&:hover fieldset': {
                                borderColor: customization.mode === "dark" ? styleVar.borderDark : styleVar.borderWhite,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: "#fa594d",
                                border: "0.5px solid #fa594d",
                                boxShadow: "0 0 1px 1px rgba(250, 89, 77, 0.5)",
                            },
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: "6px 10px",
                        },
                    },
                },
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#1c1c1c',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        color: '#ffffff',
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#333333',
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: '#444444',
                        },
                    },
                },
            },
            MuiBadge: {
                styleOverrides: {
                    badge: {
                        height: "18px",
                        width: "15px",
                        padding: 0,
                        fontSize: 12,
                        color: '#ffffff',
                        backgroundColor: '#00e676',
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    '*::-webkit-scrollbar': {
                        width: "12px",
                    },
                    '*::-webkit-scrollbar-track': {
                        background: "#f1f1f1",
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: "#888",
                        borderRadius: "10px",
                        border: "3px solid #f1f1f1",
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: "#555",
                    },
                    body: {
                        fontSize: "12px",
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: "transparent",
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderColor: customization.mode === "dark" ? styleVar.borderDark : styleVar.borderWhite,
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        borderColor: customization.mode === "dark" ? styleVar.borderDark : styleVar.borderWhite,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        borderColor: customization.mode === "dark" ? styleVar.borderDark : styleVar.borderWhite,
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        // Customize your MenuItem styles here if needed
                    },
                },
            },
        },
        mixins: {
            toolbar: {
                minHeight: "48px",
                padding: "16px",
            },
        },
        // typography: themeTypography(themeOption),
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
