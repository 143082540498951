import Router from "./route/Index";
import { ThemeProvider as MuiThemeProvider, Paper } from "@mui/material";
import { createContext, useState } from "react";
import theme from "./themes";
import { useTheme } from "./layout/provider/Theme";

export const globalThemeProvider = createContext();
export const TriggerBtnContext = createContext();

const App = () => {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const toggleFetch = () => {
    setTriggerFetch(prev => !prev);
  };

  const mode = useTheme();

  const customization = {
    mode: mode.skin,
    backgroundDark: { main: "#141c26" },
    boxShadow: {
      light: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      dark: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
    },
    cardInner: { main: { padding: "1.5rem" }, lg: { padding: "2.5rem" } },
    borderRadius: "0.625rem",
  };

  return (
    <globalThemeProvider.Provider value={theme(customization)}>
      <MuiThemeProvider theme={theme(customization)}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: customization.mode === "dark" ? customization.backgroundDark.main : "white",
            "@media (min-width: 65.5rem)": {
              background: "transparent",
            },
          }}
        >
          <TriggerBtnContext.Provider value={{ triggerFetch, toggleFetch }}>
            <Router />
          </TriggerBtnContext.Provider>
        </Paper>
      </MuiThemeProvider>
    </globalThemeProvider.Provider>
  );
};

export default App;
