import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const WalletBalanceDetails = () => {
    const [getWalletBalance, setWalletBalance] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWalletBalance = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/wallet/stats`, {
                    method: 'POST',
                    headers: {
                        authToken: Cookies.get('authToken'),
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    const error = new Error('An error occurred while getting Wallet Balance.');
                    error.info = errorData;
                    error.code = `${response.status}`;
                    throw error;
                }

                const data = await response.json();
                setWalletBalance(data);
            } catch (error) {
                console.log(`An error occurred in Wallet Balance page: ${error}`);
            } finally {
                setLoading(false);
            }
        };

        fetchWalletBalance();
    }, []);

    return { getWalletBalance, loading };
};

export default WalletBalanceDetails;
