import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from 'reactstrap';
import { useTheme } from '../../layout/provider/Theme';
import WalletBalance from './WalletBalance';
import "./dashboard.css";
import { FormControl, Grid, MenuItem, Select, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { Table, TableCell, TableRow, Paper, Badge } from '@mui/material';
import { postRequestDataForm } from '../../utils/api-services';
import { shortenString } from '../../utils/Utils';
import { CusTableBody, CusTableHead, CusTextField, CustomButton, CustomTableContainer } from '../../themes/CusComponent';

const Transactions = () => {
    const theme = useTheme();
    const [transactionData, setTransactionData] = useState([]);
    const [logType, setLogType] = useState("Transaction Log");
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const [limit, setLimit] = useState("10");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const Logs = [
        { title: "Transaction Log" },
        { title: "Pending Log" },
        { title: "Request Log" },
    ];

    const options = [
        { value: "10", label: '10' },
        { value: "25", label: '25' },
        { value: "50", label: '50' },
        { value: "100", label: '100' },
    ];

    const columns = [
        { id: 'perticulars', label: 'Perticulars', },
        { id: 'date', label: 'Date', },
        { id: 'amount', label: 'Amount', },
        { id: 'token', label: 'Token', },
        { id: 'for', label: 'For', },
        { id: 'type', label: 'Type', },
        { id: 'user', label: 'User', },
        { id: 'hashKey', label: 'Hash Key', },
        { id: 'status', label: 'Status', },
    ];

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 1000);

        // Cleanup timeout if component unmounts or if searchQuery changes before timeout finishes
        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    useEffect(() => {
        const fetchLogs = async () => {
            setLoading(true);
            try {
                var formData = new FormData();
                formData.append("limit", limit);
                formData.append("page", page);
                formData.append("search_keyword", debouncedSearchQuery);

                let endpoint = '';
                const type = { token: "token", cash: "cash" };
                if (logType === 'Transaction Log') {
                    endpoint = 'api/v1/wallet/logs';
                } else if (logType === 'Pending Log') {
                    endpoint = 'api/v1/wallet/request-logs';
                    formData.append("type", type.token);
                } else if (logType === 'Request Log') {
                    endpoint = 'api/v1/wallet/request-logs';
                    formData.append("type", type.cash);
                }

                const response = await postRequestDataForm(formData, endpoint);
                if (response?.status) {
                    setTransactionData(response?.data?.logs || []);
                    setTotalPages(Math.ceil(response?.data?.allTransactionCount / limit));
                }
            } catch (error) {
                console.log(`An error occurred while fetching logs: ${error}`);
            } finally {
                setLoading(false);
            }
        };

        fetchLogs();

        // Cleanup function to avoid memory leaks
        return () => {
            setTransactionData([]);
        };
    }, [logType, limit, debouncedSearchQuery, page]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <Container fluid className='px-4 py-2'>
            <Row className="">
                <WalletBalance />
                <Col xs={12} sm={6} md={8}>
                    <Card>
                        <CardBody className="rounded-5 side-shadow" style={{ height: "6.9rem" }}>
                            {/* Add some meaningful content here */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Grid container spacing={0} className="my-2">
                <Grid item xs={12} sm={6} md={6.3}>
                    <Grid container spacing={1}>
                        {Logs.map((item) => (
                            <Grid item key={item.title}>
                                <CustomButton
                                    size='small'
                                    fullWidth
                                    className={`rounded-pill px-3 py-1 ${logType === item.title ? 'active' : ''}`}
                                    style={{ color: theme.skin === "dark" ? '#fff' : "" }}
                                    onClick={() => { setLogType(item.title); setPage(1); }} // Reset to page 1 on log type change
                                >
                                    {item.title}
                                </CustomButton>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={5.7} className="d-flex justify-content-between">
                    <Grid item>
                        <CusTextField
                            type='text'
                            size="small"
                            label="Search"
                            variant="outlined"
                            value={searchQuery}
                            onChange={(e) => { setSearchQuery(e.target.value) }}
                            sx={{
                                borderRadius: '50px',
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                    borderRadius: '50px',
                                    height: 35,
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined">
                            <Select
                                size="small"
                                id="limitSelect"
                                value={limit}
                                onChange={(e) => { setLimit(e.target.value); setPage(1); }} // Reset to page 1 on limit change
                                sx={{
                                    border: "1px solid #D0DEFC", width: 80, height: 35,
                                    color: theme.skin === "dark" ? "#fff" : "#000",
                                    '& .MuiSelect-icon': {
                                        color: theme.skin === "dark" ? "#fff" : "#000", // Arrow color
                                      },
                                }}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item className="d-flex justify-content-between align-items-center">
                        <Button
                            onClick={() => handlePageChange(page - 1)}
                            disabled={page === 1}
                            size="small"
                        >
                            Previous
                        </Button>
                        <Typography className="py- px-1" variant="body2">
                            Page {page} of {totalPages}
                        </Typography>
                        <Button
                            onClick={() => handlePageChange(page + 1)}
                            disabled={page === totalPages}
                            size="small"
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <div className='container-fluid'>
                <Row>
                    <CustomTableContainer component={Paper}
                        sx={{
                            background: theme.skin === "dark" ? "#a5a6a91c" : "",
                            '& .MuiTableCell-root': {
                                color: theme.skin === "dark" ? "#fff" : "#000",
                                //   background: theme.skin === "dark" ? "#a5a6a91c" : "",
                            },
                        }}>
                        <Table stickyHeader>
                            <CusTableHead
                                sx={{
                                    '& .MuiTableCell-root': {
                                        color: theme.skin === "dark" ? "#fff" : "#000",
                                    }
                                }}
                            >
                                <TableRow>
                                    <TableCell className='p-0'>#</TableCell>
                                    {columns.map((item, index) => (
                                        <TableCell className='py-1 ps-1' key={index}>
                                            {item.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </CusTableHead>
                            <CusTableBody>
                                {
                                    loading
                                        ? <TableRow>
                                            <TableCell colSpan={columns.length + 1} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                        : transactionData.length > 0
                                            ? transactionData.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className='py-1 px-1'>{(page - 1) * limit + index + 1}</TableCell>
                                                    <TableCell className='p-0'>{shortenString(item.perticulars, 15)}</TableCell>
                                                    <TableCell className='py-1 px-1'>{item.time}</TableCell>
                                                    <TableCell className='py-1 px-1'>{item.txn_amount.toFixed(2)}</TableCell>
                                                    <TableCell className='py-1 px-1'>{item.token_value.toFixed(6)}</TableCell>
                                                    <TableCell className='py-1 px-1'>{item.txn_for}</TableCell>
                                                    <TableCell className='py-1 px-1'>{item.txn_type}</TableCell>
                                                    <TableCell className='py-1 px-1'>{item.txn_user}</TableCell>
                                                    <TableCell className='py-1 px-1'>{shortenString(item.hash_key, 15)}</TableCell>
                                                    <TableCell className='py-1 px-1'>{item.txn_status}</TableCell>
                                                </TableRow>
                                            ))
                                            : <TableRow>
                                                <TableCell colSpan={columns.length + 1} align="center">
                                                    <Typography variant="body2">
                                                        No transaction logs found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                }
                            </CusTableBody>
                        </Table>
                    </CustomTableContainer>
                </Row>
            </div>
        </Container>
    );
};

export default Transactions;
